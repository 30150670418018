

const Index = () => {
    return (
        <div id='indexContainer'>
            <div id="zero-state">
                <h1>Hello! :)</h1>
            </div>
        </div>
        
    )
}

export default Index